import { CartItemType } from "@src/types/data";
import { t_buy_log, t_cart, t_library, t_usr_info } from "@src/types/tables";
import { getCookie } from "./util/cookie";
import jwt from "jsonwebtoken";
import CartService from "@src/services/cart/CartService";
import { bucketParams, downloadParams, s3 } from "@pages/api/bucket";
import AuthService from "@src/services/auth/AuthService";
import Router from "next/router";
import axios from "axios";

export function cvtJson2User(json: any): t_usr_info
{
    let user: t_usr_info = {
        userId: json.USR_ID,
        userPass: json.USR_PW,
        userName: json.USR_NM,
        email: json.EMAIL,
        userNick: json.USR_NIC_NM,
        telNomber: json.TELNO,
        adress: json.ADDRESS,
        postCode: json.POST_CODE,
        emailRecvCode: json.EMAIL_RECV_CD,
        smsRecvCode: json.SMS_RECV_CD,
        snsCertify: json.SNS_CERTIFY,
        profileImgUrl: json.PROFILE_IMG_URL,
        userTypeCode: json.USR_TYPE_CD,
        useCode: json.USE_CD,
        blockCode: json.BLOCK_CD,
        etc: json.ETC,
        regDate: json.REG_DTM,
        regUserId: json.REG_USR_ID,
        regUserIp: json.REG_USR_IP,
        modifyDate: json.MODIFY_DTM,
        modifyUserId: json.MODIFY_USR_ID,
        modifyUserIp: json.MODIFY_USR_IP,
        userJoinpath: json.USR_JOINPATH,
        loginDate: json.LOGIN_DTM,
        enter_pass: json.ENTER_PASS
    }

    return user;
}

export function cvtNum2Time(total: number): string
{
    let minute: number = Math.floor(total / 60);
    let second: number = total % 60;
    
    return minute + ":" + second;
}

export function cvtJson2Item(json: any): CartItemType
{
    let item: CartItemType = {
        id: json.LIBRARY_ID,
        title: json.LIBRARY_NM,
        price: json.LIBRARY_PRICE,
        discountRate: json.DISCOUNTRATE,
        isCheck: true,
        genre: json.LIBRARY_GENRE_CD,
        artist: json.ARTIST,
        desc: json.LIBRARY_DESC
    }

    return item;
}

export function cvtJson2Library(json: any): t_library
{
    let library: t_library = {
        seq: json.LIBRARY_SEQ ? json.LIBRARY_SEQ : null,
        id: json.LIBRARY_ID ? json.LIBRARY_ID : null,
        title: json.LIBRARY_NM ? json.LIBRARY_NM : null,
        bpm: json.BPM ? json.BPM : null,
        genre: json.LIBRARY_GENRE_CD ? json.LIBRARY_GENRE_CD : null,
        nation: json.NATION ? json.NATION : null,
        singer: json.SINGER ? json.SINGER : null,
        artist: json.ARTIST ? json.ARTIST : null,
        lyricsWriter: json.LYRICS_WRITER ? json.LYRICS_WRITER : null,
        Arranger: json.ARRANGER ? json.ARRANGER : null,
        desc: json.LIBRARY_DESC ? json.LIBRARY_DESC : null,
        albumName: json.ALBUM_NM ? json.ALBUM_NM : null,
        libImgId: json.LIBRARY_IMG_FILE_ID ? json.LIBRARY_IMG_FILE_ID : null,
        referenceName: json.REFERENCE_NM ? json.REFERENCE_NM : null,
        sampleFileId: json.LIBRARY_SAMPLE_FILE_ID ? json.LIBRARY_SAMPLE_FILE_ID : null,
        wavFileId: json.LIBRARY_SALE_WAV_FILE_ID ? json.LIBRARY_SALE_WAV_FILE_ID : null,
        mp3FileId: json.LIBRARY_SALE_MP3_FILE_ID ? json.LIBRARY_SALE_MP3_FILE_ID : null,
        price: json.LIBRARY_PRICE ? json.LIBRARY_PRICE : null,
        discountRate: json.DISCOUNTRATE ? json.DISCOUNTRATE : null,
        libOpenCode: json.LIBRARY_OPEN_CD ? parseInt(json.LIBRARY_OPEN_CD) : 0,
        libDetailOpenCode: json.LIBRARY_DETAIL_OPEN_CD ? parseInt(json.LIBRARY_DETAIL_OPEN_CD) : 0,
        tags: json.LIBRARY_TAG ? json.LIBRARY_TAG : null,
        key: json.LIBRARY_KEY ? json.LIBRARY_KEY : null,
        formCode: json.LIBRARY_FORM_CD ? json.LIBRARY_FORM_CD : null,
        openCode: json.LIBRARY_OPEN_CD ? json.LIBRARY_OPEN_CD : null,
        viewCnt: json.VIEW_CNT ? json.VIEW_CNT : null,
        recmdCnt: json.RECMD_CNT ? json.RECMD_CNT : null,
        copyrightId: json.COPYRIGHT_ID ? json.COPYRIGHT_ID : null,
        checkPerson: json.CHECK_PERSON ? json.CHECK_PERSON : null,
        Url: json.URL ? json.URL : null,
        regDate: json.REG_DTM ? json.REG_DTM : null,
        regUserId: json.REG_USR_ID ? json.REG_USR_ID : null,
        regUserIp: json.REG_USR_IP ? json.REG_USR_IP : null,
        modifyDate: json.MODIFY_DTM ? json.MODIFY_DTM : null,
        modifyUserId: json.MODIFY_USR_ID ? json.MODIFY_USR_ID : null,
        modifyUserIp: json.MODIFY_USR_IP ? json.MODIFY_USR_IP : null,
        mood: json.MOOD_CD ? json.MOOD_CD : null,
        scene: json.SCENE_CD ? json.SCENE_CD : null,
        theme: json.THEME_CD ? json.THEME_CD : null,
        SOUND_EFFECT_CD: json.SOUND_EFFECT_CD ? json.SOUND_EFFECT_CD : null,
        bgm: json.BGM_CD ? json.BGM_CD : null,
        instrument: json.LIBRARY_INST_CD ? json.LIBRARY_INST_CD : null,
        tempo: json.TEMPO ? json.TEMPO : null,
    }

    return library;
}

export function cvtJson2BuyLog(json: any): t_buy_log
{
    let buyLog: t_buy_log = {
        cashUseLogSeq: json.CASH_USE_LOG_SEQ,
        libraryUseId: json.LIBRARY_USE_ID,
        userId: json.USR_ID,
        userRightSeq: json.USR_RIGHT_SEQ,
        payCashDate: json.PAY_CASH_DTM,
        regDate: json.REG_DTM,
        regUserId: json.REG_USR_ID,
        regUserIp: json.REG_USR_IP,
        modifyDate: json.MODIFY_DTM,
        modifyUserId: json.MODIFY_USR_ID,
        modifyUserIp: json.MODIFY_USR_IP
    }

    return buyLog;
}

export function cvtJson2Cart(json: any): t_cart
{
    let cart: t_cart = {
        cartSeq: json.CART_SEQ,
        userId: json.USR_ID,
        libraryId: json.LIBRARY_ID,
        regDate: json.REG_DTM,
        regUserId: json.REG_USR_ID,
        regUserIp: json.REG_USR_IP,
        modifyDate: json.MODIFY_DTM,
        modifyUserId: json.MODIFY_USR_ID,
        modifyUserIp: json.MODIFY_USR_IP
    }

    return cart;
}


export function decodeToken(req?: any)
{
    let token = process.browser ? getCookie("accessToken") : getCookie("accessToken", req);
    let decode = jwt.decode(token);

    return decode!;
}

export function addCart(libId: string)
{
    if (!!getCookie("accessToken"))
    {
        let userId: string = decodeToken().sub as string;
        let data = { libraryId: libId, userId: userId }
        
        CartService.addItem(data);
    }
}

export function deleteCart(libId: string)
{
    if (!!getCookie("accessToken"))
    {
        let userId: string = decodeToken().sub as string;
        let data = { libraryId: libId, userId: userId }
        
        CartService.deleteItems(data);
    }
}

export async function uploadFile(file: File)
{
    const params = bucketParams(file, file.name, file.type);

    s3.upload(params, (err: any, data: any) => {
        if (err) {
            throw err
        }
    })
}

export function categoryCode2Id(code: string)
{
    if (code === "LIB00001")
        return "CONTENTS_TYPE";
    else if (code === "LIB00002")
        return "LIBRARY_GENRE_CD";
    else if (code === "LIB00003")
        return "MOOD_CD";
    else if (code === "LIB00004")
        return "THEME_CD";
    else if (code === "LIB00005")
        return "SCENE_CD";
    else if (code === "LIB00006")
        return "SOUND_EFFECT_CD";
    else if (code === "LIB00007")
        return "BGM_CD";
    else if (code === "LIB00009")
        return "LIBRARY_INST_CD";
    else if (code === "LIB00010")
        return "NATION";
}

export function categoryName2Id(name: string)
{
    if (["콘텐츠 형태", "Content Form Type"].includes(name))
        return "CONTENTS_TYPE";
    else if (["장르", "Genre"].includes(name))
        return "LIBRARY_GENRE_CD";
    else if (["분위기", "Mood"].includes(name))
        return "MOOD_CD";
    else if (["테마", "Theme"].includes(name))
        return "THEME_CD";
    else if (["장면", "Scene"].includes(name))
        return "SCENE_CD";
    else if (["소리효과", "Sound Effect"].includes(name))
        return "SOUND_EFFECT_CD";
    else if (["배경음", "Bgm"].includes(name))
        return "BGM_CD";
    else if (["악기", "Instrument"].includes(name))
        return "LIBRARY_INST_CD";
    else if (["국가", "Nation"].includes(name))
        return "NATION";
}

export function handleLogIn(text: string)
{        
    alert(text);
    Router.push("/login");
}

export function handleLogOut(text: string)
{        
    let logoutConfirm: boolean = confirm(text)
    
    if (logoutConfirm)
    {
        AuthService.signOut();
        Router.replace("/");
    }
}

export function stripHtmlTags(htmlString: string)
{
    if (!htmlString)
        return ""

    return htmlString.replace(/<\/?[^>]+(>|$)/g, "");
}

export function initTranslate()
{
    let scriptId = "google_trans_api"
    let selectElement = document.querySelector('.goog-te-combo');

    if (selectElement)
        selectElement.remove();

    let addScript = document.createElement('script');

    addScript.id = scriptId;
    addScript.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    document.body.appendChild(addScript);
}